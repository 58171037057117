import React from 'react'
import * as URLS from '../../constants/urls'
import IdeaCard from './IdeaCard'
import IdeasGrid from './IdeasGrid'
import './style.css'

const getIdeasJson = url =>
([
  {
    "id": "chart-library",
    "title": "Chart library for pharo",
    "img": "milton-chart.png",
    "supervisors": [
      "Milton Mamani Torres",
      "Oleksandr Zaitsev"
    ],
    "description": "Charts like scatter plot, bar plot, histogram, line plot and combined plots are essential for any ecosystem. You have to create a nice usable readable and extensible chart library that users of Pharo can use it for their projects, replacing the chart library of Roassal3-Charts  ",
    "goal": "- Student will contribute with Pharo ecosystem.\n- A chart library that people can use and extend",
    "keywords": [
      "Chart",
      "Roassal",
      "Data Visualization",
      "Plots"
    ],
    "size": "Large",
    "difficulty": "Medium",
    "skills": {
      "required": [
        "Pharo"
      ],
      "preferred": [
        "Desire to Learn"
      ]
    }
  },
  {
    "id": "test-coverage-visualization",
    "title": "Improving Test Coverage Visualization Tools",
    "img": "juanpablo-coverage.png",
    "supervisors": [
      "Juan Pablo Sandoval",
      "Alison Fernandez",
      "Geraldine Galindo"
    ],
    "description": "This projects targets to improve the Pharo test coverage tool by\n1. providing a visual support to display the code coverage and\n2. improving the usability of the tool.\n\n\n",
    "goal": "- A visual reusable component that shows the code coverage of a Pharo method;\n- Integrate this component with the standard Pharo testing tool;\n- Fix and improve a number of components of the Pharo testing tool to improve their usability.",
    "keywords": [
      "Testing",
      "Code Coverage",
      "UI"
    ],
    "size": "Medium",
    "difficulty": "Medium",
    "skills": {
      "required": [
        "OOP"
      ],
      "preferred": [
        "Spec2"
      ]
    }
  },
  {
    "id": "slang-vm-transpiler",
    "title": "Pharo-to-C VM Transpiler",
    "img": "guille-slang.png",
    "supervisors": [
      "Guillermo Polito",
      "Pablo Tesone",
      "Nahuel Palumbo"
    ],
    "description": "The objective of this project is to bring general improvements in the Pharo-to-C transpiler and VM generation framework, named Slang. Slang supports type inference, inlinings, and simple optimisations such as constant folding and propagation. Most of Slang is implemented through AST manipulations and later dumped as C code.",
    "goal": "The objective of this project is to fix some of the open issues tagged as `slang` in the [pharo-vm repository](https://github.com/pharo-project/pharo-vm):\n\n - Fixes in dispatch table translations ([pharo-vm #234](https://github.com/pharo-project/pharo-vm/issues/234))\n- asSymbol can be sent only to contants ([pharo-vm #236](https://github.com/pharo-project/pharo-vm/issues/236))\n- Inline does not always work with subexpressions ([pharo-vm #241](https://github.com/pharo-project/pharo-vm/issues/241))\n- Improve the declaration of nested structures ([pharo-vm #242](https://github.com/pharo-project/pharo-vm/issues/242))\n- Inlines do not respect type annotations ([pharo-vm #244](https://github.com/pharo-project/pharo-vm/issues/244))\n- Improve binary selector translation ([pharo-vm #246](https://github.com/pharo-project/pharo-vm/issues/246))\n- Improve variable localisation ([pharo-vm #247](https://github.com/pharo-project/pharo-vm/issues/247))\n- Improve variable renaming ([pharo-vm #429](https://github.com/pharo-project/pharo-vm/issues/429))\n- Improve block inlining ([pharo-vm #430](https://github.com/pharo-project/pharo-vm/issues/430))\n- Improve block argument inlining ([pharo-vm #432](https://github.com/pharo-project/pharo-vm/issues/432))\n- Improve translation of struct accesses inside assertions ([pharo-vm #458](https://github.com/pharo-project/pharo-vm/issues/458))",
    "keywords": [
      "Transpilation",
      "Compilation"
    ],
    "size": "Large",
    "difficulty": "Hard",
    "skills": {
      "required": [
        "Compilers"
      ],
      "preferred": [
        "OOP"
      ]
    }
  },
  {
    "id": "data-frame",
    "title": "DataFrame",
    "img": "dataframe.png",
    "supervisors": [
      "Cyril Ferlicot-Delbecque",
      "Oleksandr Zaitsev",
      "Serge Stinckwich"
    ],
    "description": "DataFrame was introduced to Pharo during the GSoC 2017 (https://github.com/PolyMathOrg/DataFrame). The work on DataFrame continued and many new features were introduced by different contributors. However, it is still a work in progress and a huge portion of functionality that can be found in other data frame packages (e.g. R, pandas) is missing.",
    "goal": "The goal of this project is to introduce new functionality to DataFrame and write examples of applying DataFrame to real problems of Data Science (one can reproduce examples from pandas and scikit-learn documentation, as well as some examples from R-bloggers)",
    "keywords": [
      "data science",
      "data analysis",
      "dataframe",
      "tabular data",
      "statistics"
    ],
    "size": "Medium",
    "difficulty": "Medium",
    "skills": {
      "required": [
        "Pharo",
        "Communication skills"
      ],
      "preferred": []
    }
  },
  {
    "id": "graph-libraries-integration",
    "title": "Graph libraries Integration and redesign",
    "img": "paris-graph.png",
    "supervisors": [
      "Gordana Rakic",
      "Sebastian Jordan"
    ],
    "description": "There is a graph library in Pharo implemented within PharoAI https://github.com/pharo-ai/graph-algorithms. This library provides a user with several basic graph algorithms. Separately, a graph layouting library  has implemented basic layout algorithms https://github.com/medicka/graPharo. Redesign of these libraries and their consolidation, including adding new layout algorithms  is very significant for faster progress of different Pharo projects, especially PharoAI. Finally, we would like to implement new graph theory algorithms to add them to the library.",
    "goal": "To provide a solid and validated API for those libraries. That API should accept any kind of Object that represents a graph.",
    "keywords": [
      "Graphs Algorithm",
      "Libraries",
      "API"
    ],
    "size": "Large",
    "difficulty": "Medium",
    "skills": {
      "required": [
        "Pharo"
      ],
      "preferred": [
        "Graph theory knowledge"
      ]
    }
  },
  {
    "id": "complex-network-library",
    "title": "Complex network library on top of  PharoAI graph library",
    "img": "neuralnetwork.jpg",
    "supervisors": [
      "Gordana Rakic",
      "Sebastian Jordan"
    ],
    "description": "To complement to the basic graph theory algorithm that we have, we would like to implement complex networks in Pharo. For that, we will use as a base the existing graph library in Pharo.",
    "goal": "An implementation of complex network library on top of  PharoAI graph library  https://github.com/pharo-ai/graph-algorithms",
    "keywords": [
      "Graphs Algorithm",
      "Libraries",
      "API"
    ],
    "size": "Large",
    "difficulty": "Medium",
    "skills": {
      "required": [
        "Pharo"
      ],
      "preferred": [
        "Graph theory knowledge"
      ]
    }
  }
])




class IdeasPage extends React.Component {
  constructor() {
    super()

    this.ideas = getIdeasJson(URLS.IDEAS_FILE)

    this.state = {
      ideasToDisplay: this.ideas,
      filters: {
        selectedLevel: null,
        selectedKeywords: null,
      }
    }


    this.filterLevel = this.filterLevel.bind(this);
    this.filterKeywords = this.filterKeywords.bind(this);
  }

  applyFilters(filters) {
    var filteredIdeas = this.ideas;

    if (filters.selectedLevel) {
      filteredIdeas = filteredIdeas
        .filter(idea => idea.difficulty === filters.selectedLevel);
    }

    if (filters.selectedKeywords) {
      filteredIdeas = filteredIdeas
        .filter(idea => idea.keywords
          .some(x => filters.selectedKeywords.includes(x)));
    }

    this.setState({ ideasToDisplay: filteredIdeas, filters });
  }

  filterLevel(selectedLevel) {
    this.applyFilters({ ...this.state.filters, selectedLevel: selectedLevel });
  }

  filterKeywords(selectedKeywords) {
    this.applyFilters({ ...this.state.filters, selectedKeywords: selectedKeywords });
  }

  componentDidMount() {
    document.title = "Pharo GSoC '23 Projects";
  }

  render() {
    return (
      <div className="container">
        <h1>Pharo GSoC '23 Projects</h1>
        <p>Pharo is an open source project. We are happy to share that we always have projects in which we work from students from all over the world.
          We participated on several editions of the <a href="https://summerofcode.withgoogle.com/" target="_blank">Google Summer of Code</a>.
          Here we show the projects on which we are currently working for the 2023 edition of the Google Summer of Code.
          You can check our dedicated GSCO website for more information! <a href="https://gsoc.pharo.org/" target="_blank">gsoc.pharo.org</a>
        </p>
        {
          chunk(this.state.ideasToDisplay, 5).map((chunk, i) =>
            <IdeasGrid key={i}>
              {
                chunk.map((idea) =>
                  <IdeaCard
                    key={idea.id}
                    idea={idea}
                  />
                )
              }
            </IdeasGrid>
          )
        }
      </div>
    )
  }
}

export function chunk(list, n) {
  const copy = Array.from(list)
  const result = []
  while (copy.length) {
    result.push(copy.splice(0, n))
  }
  return result
}

export default IdeasPage
