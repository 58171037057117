import React, { useEffect } from 'react'
import split from '../../img/split-croatia.jpg'
import red from '../../img/red.jpg'

export default function HomePage() {
  useEffect(() => {
    document.title = "Immersive Pharo Summer School 2023"
  }, []);

  return (
    <div className="container">
      {/* <img src={split} alt=''  /> */}

      <div style={{
        backgroundImage: `url(${split})`
      }}>
        <br /><br /><br /><br />
        <h1>Immersive Pharo Summer School 2023<br />
          Split, Croatia - September 11th to 15th</h1>
        <br /><br /><br /><br /><br /><br />
      </div>

      <p>Dear students,</p>
      <p>We are happy to announce that once again, Pharo Consortium is organizing an international <b><i>cost-free</i></b> summer school.</p>
      <p>Pay attention the places are limited because this is a cool summer school in a cool place.</p>
      <p>If you have any additional questions, feel free to send an email to <a href="mailto:stephane.ducasse@inria.fr">stephane.ducasse@inria.fr</a> or <a href="mailto:goca@dmi.uns.ac.rs">goca@dmi.uns.ac.rs</a></p>


      <h2>Timeline</h2>
      <ul>
        <li>
          09h00 - 10h30: Morning courses
        </li>
        <li>
          10h30 - 10h45: Morning coffee break
        </li>
        <li>
          10h45 - 12h15: Morning courses
        </li>
        <li>
          12h15 - 13h15: Lunch time
        </li>
        <li>
          13h15 - 14h45: Afternoon courses
        </li>
        <li>
          14h45 - 15h00: Coffee break
        </li>
        <li>
          15h00 - 16h30: Afternoon courses
        </li>
      </ul>


      <h2>How to find the classroom</h2>
      <p>
        "Referentni centar" next to cafe "Fanki". Ask at the entrance about the school, please.
        If you cannot find the classroom, please write us on <a href="https://discord.gg/QewZMZa"> Discord </a> or by email.
      </p>

      <h2>Registration</h2>
      <p>
        To register, please fill the following <a href="https://docs.google.com/forms/d/e/1FAIpQLSfLWigdBnaTWYDnSdDry4SEGN65i8pMEpP84j2Bl7TtseOkJg/viewform" target='blank'>Form</a>
        <br /> The deadline for applications is July 15, 2023.
      </p>

      <h2>Summer School Program</h2>

      <p>Live Immersive Programming with Pharo</p>

      Keyworks: Reflective, OO design, IA, XTDD, Visualization, Scripting C libraries, Live coding, mastering your debugger

      <h3>Day One: Pharo in one Day</h3>
      During this day you will learn the survival kit to code in Pharo.
      You will learn the syntax and the main tools to code and publish code.
      After that day you will be able to code in Pharo. Yes the syntax fits in half of a postcard.

      <ul>
        <li>Pharo for the scientists </li>
        <li>Discover and experience eXTreme Test Driven Design</li>
        <li>Hands-on with ProfStef</li>
        <li> Understand the key tools for coding, debugging, inspecting and publishing code</li>
        <li>Handon: coding the counter</li>
      </ul>


      <h3>Day Two: Crafting user interfaces</h3>
      During this day you will learn how to define user interfaces using the Spec UI builder framework.
      We will also show you how to extend tools to shape them to your own needs.
      We will also build a simple web server in a couple of pages to revisit the Pharo syntax.
      You will see how you can build nontrivial programs in an immersed way by interacting with life objects.

      <ul>
        <li>Simple tour of Spec</li>
        <li>Build a little App with Spec todo App in 15 min</li>
        <li> Tools are shaping your mind: Define your own tool.
          Extend the environment Graph inspector</li>
        <li>Revisit the syntax building a web server</li>
      </ul>

      <h3>Day Three: IA with Pharo</h3>
      During that day we will focus on AI and visualization. You will learn about DataFrame (how to grab, and collect data),
      train a model and validate your results. We will show how you can understand your data and results using the Roassal scripting visualization engine
      available from <a href="http://agilevisualization.com">http://agilevisualization.com</a>.

      <ul>
        <li>Getting started with machine learning</li>
        <li> TeaLight and machine learning: you will connect a web server to machine learning</li>
        <li> Data Frame.</li>
        <li> Train a model.</li>
        <li> Validate a  model.</li>
        <li> Using live visualization scripting of Roassal <a href="http://agilevisualization.com">http://agilevisualization.com</a> to script interactive visualization.</li>
      </ul>

      <h3>Day Four: Object-oriented Design and Software Engineering</h3>
      During this day you will rethink your OO understanding. At the end of this day, you will not think or program object-oriented the same way.
      Experts in object-oriented design will give you keys that will change how you will code in any object-oriented language in the future.
      We will also present advanced software engineering techniques. Finally, we will show you how we can script VLC from Pharo using native calls.

      <ul>
        <li> Rethink your OOP skills: Understand late binding for real. </li>
        <li> Advanced Tools: Automatic Rewriting, Refactorings, Finder.</li>
        <li> Scripting VLC from Pharo.</li>
        <li>Lapack binding: how to get the full power of Fortran libraries but with the coolness of Pharo.</li>
      </ul>

      <h3>Day Five: Code Analysis</h3>
      During this day we will present the
      Moose <a href="http://modularMoose.org">http://http://modularMoose.org</a> is a powerful platform to define and apply
      software analyses.

      <ul>
        <li> You will define a meta-model to represent commits, files, and authors.</li>
        <li> You will define specific visualizations in Roassal to display information.</li>
      </ul>


      <h2>Summer School Speakers</h2>

      <h3>Stéphane Ducasse  <a href="https://stephane.ducasse.free.fr">https://stephane.ducasse.free.fr</a></h3>
      <p>
        I'm an expert in object design, object language design, reflective programming, and the maintenance and evolution of large applications (visualization, metrics, meta-modeling). My work on traits has been introduced in AmbientTalk, Slate, Pharo, Perl-6, PHP 5.4 and Squeak. They have been ported to JavaScript. It has influenced the Scala and Fortress languages. I'm one of the founders of Pharo ( <a href="http://www.pharo.org/">http://pharo.org</a>). I head its industry consortium <a href="http://consortium.pharo.org/">http://consortium.pharo.org</a>. I'm one of the designers of Moose, an analysis platform (<a href="http://modularmoose.org/">modularmoose.org</a>).
        I regularly work with companies such as Arolla, Berger-Levrault, Thales, Framatome, Siemens, CIM, Worldline to solve their challenges.
      </p>

      <h3>Gordana Rakic</h3>
      <p>
        Gordana Rakic is an assistant with PhD in Computer Science and Software Engineering at the University of NoviSad Serbia.
        She is an expert in program analysis, metamodel, object-oriented programming, software metrics, clone detection.
        She is teaching object-oriented design and testing.
        <a href="https://scholar.google.com/citations?user=r4OXEPwAAAAJ" target='blank'>https://scholar.google.com/citations?user=r4OXEPwAAAAJ</a>
      </p>

      <h3>Sebastian Jordan-Montaño</h3>
      <p>
        Sebastian Jordan-Montaño graduated as Software engineering from the Catholic University of Cochabamba, Bolivia.
        He worked as Inria as an engineer in the <a href="https://github.com/pharo-ai/" target='blank'>pharo-ai</a> project and he is an expert in data mining, object-oriented design and programming,
        tool development, and software visualization.
      </p>

      <h3>Nour-Jihene Agouf</h3>
      <p>
        Nour-Jihene graduated from the <i>University 20 august 1955</i> of Skikda, Algeria. She is an expert in program analysis, program visualization,
        object-oriented design, and software visualization.
      </p>

    </div>
  );
}
