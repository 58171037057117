import React, { useEffect } from 'react'

export default function LearningResources() {
    useEffect(() => {
        document.title = "Pharo Learning Resources"
    }, []);

    return (
        <div className="container">
            <h1>Pharo Learning Resources</h1>
            <p>Here you can find some learning resources to start your journey into Pharo!</p>
            <ul>
                <li>
                    The Pharo Mooc <a href="https://mooc.pharo.org/" target="_blank" >https://mooc.pharo.org/</a>
                </li>
                <li>
                    Some cool Pharo books <a href="https://books.pharo.org/" target="_blank" >https://books.pharo.org/</a>
                </li>
                <li>
                    Pharo on Exercism <a href="https://exercism.org/tracks/pharo-smalltalk/" target="_blank" >https://exercism.org/tracks/pharo-smalltalk</a>
                </li>
                <li>
                    You can always get support from the community via its Discord server <a href="https://discord.gg/QewZMZa" target="_blank" >https://discord.gg/QewZMZa</a>
                </li>
            </ul>

            <h2>Testimonies</h2>
            Some of the lectures are based on an excellent lecture freely available at <a href="http://mooc.pharo.org" target="_blank">http://mooc.pharo.org</a>.
            Here are some testimonies
            <ul>
                <li><div>I have just completed week seven of the Pharo Mooc (beginner and object-oriented tracks) I am starting a redo of the Mooc with the web track (TinyBlog project).  I have already learned so much! I have spent the last 20 years or so in software development and, following this Mooc, I realized I hadn’t really grasped the essence of object-oriented design. - Anonymous </div></li>

                <li><div>« Really one of the best mooc I have ever attended. And I have attended quite a few (openSAP, openHPI). As an old fashioned ABAP developer, I want to be reborn as Pharo developer in my next life :-) » - Anonymous</div></li>

                <li><div> Hi! I finished the MOOC some weeks ago and I would like to congratulate everybody involved! After a decade+ of Python programming, I think I found my new favorite language :). I'm making a small Teapot server for Slack command bots, I'm gonna push it to Github (yay Iceberg), if anyone is interested. - EduardoPadoan</div></li>

                <li><div>I just completed the @pharoproject Mooc the best investment I have ever made of my time. MAQBOOL </div></li>

                <li><div>Hey all - I've just finished the Mooc - thanks for an excellent course and a thouroughly interesting look at a new way to program :smile: Looking forward to starting to play with Pharo on some upcoming ideas I've had. - Tieryn</div></li>

                <li><div>As much as I thought I understand object-orientation, it is very clear NOW that without a truly useable Smalltalk, which Pharo is, it is impossible to really understand and exercise object orientation. Thank you all soooo much. - Mike D. 06/12/2020 </div></li>
            </ul>
        </div>
    );
}
