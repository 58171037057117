import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom';

import Navigation from '../Navigation';
import IdeasPage from '../IdeasPage';
import IdeaPage from '../IdeaPage';
import HomePage from '../HomePage';
import LearningResources from '../LearningResources';
import SplitConferencePage from '../SplitConference';

import * as ROUTES from '../../constants/routes';

const App = () => (
  <Router>
    <div>
      <Navigation />
      <Routes>
        <Route exact path={ROUTES.IDEAS} element={ <IdeasPage/> } />
        <Route exact path={ROUTES.HOME} element={ <HomePage/> } />
        <Route exact path={ROUTES.LEARNING} element={ <LearningResources/> } />
        <Route exact path={ROUTES.VENUE} element={ <SplitConferencePage/> } />
        <Route exact path={'/:id'} element={ <IdeaPage/>} />
      </Routes>
    </div>
  </Router>
);

export default App;
