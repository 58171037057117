import React from 'react';
import { Link } from 'react-router-dom';

import './style.css';
import * as ROUTES from '../../constants/routes';

import logo from '../../img/logo-small.png';

const Navigation = () => (
  <div id="navbar">
    <div id="navbar-buttons">
      <ul>
        <li>
          <Link to={ROUTES.HOME}><img src={logo} alt='' /></Link>
        </li>
        <li>
          <Link className="navbar-button" to={ROUTES.HOME}>Home</Link>
        </li>
        <li>
          <Link className="navbar-button" to={ROUTES.VENUE}>Conference Venue</Link>
        </li>
        <li>
          <Link className="navbar-button" to={ROUTES.LEARNING}>Learning Resources</Link>
        </li>
        <li>
          <Link className="navbar-button" to={ROUTES.IDEAS}>GSoC '23 Projects</Link>
        </li>
      </ul>
    </div>
  </div>
);

export default Navigation;
